  // import Swiper bundle with all modules installed
  import Swiper from 'swiper/bundle';

  function swipers() {
    // init Swiper:
    const swiper = new Swiper('.skol-swiper-featured', {
        //Optional parameters
        direction: 'horizontal',
        speed: 700,
        // autoplay: true,
        autoplay: {
            delay:5000,
            pauseOnMouseEnter:true,
        },
        slidesPerView: 1,
        spaceBetween: 10,

        // If we need pagination
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },

        grabCursor: true,

    });
      
}

swipers();

export { swipers };
